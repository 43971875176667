import axios from 'axios';
import { toast } from 'react-toastify'; // eslint-disable-next-line
import config from '../config/config'; // eslint-disable-next-line
import masonic_craft_gldc from '../../assets/img/lodges/masonic_craft_gldc.png';
import masonic_craft_glny from '../../assets/img/lodges/masonic_craft_glny.png';
import masonic_craft_glnj from '../../assets/img/lodges/masonic_craft_glnj.png';
import masonic_craft_glks from '../../assets/img/lodges/masonic_craft_glks.png';
import masonic_craft_glfl from '../../assets/img/lodges/masonic_craft_glfl.png';
import masonic_craft_glspr from '../../assets/img/lodges/masonic_craft_glspr.png';
import masonic_sr_srsj from '../../assets/img/lodges/masonic_sr_srsj.png';
import masonic_oes_tx_pha from '../../assets/img/lodges/masonic_oes_tx_pha.png';
import ahepa from '../../assets/img/lodges/ahepa.png';
import masonic_sr from '../../assets/img/lodges/masonic_sr.png';
import masonic_shrine from '../../assets/img/lodges/masonic_shrine.png';
import masonic_craft from '../../assets/img/lodges/masonic_craft.png';
import default_icon from '../../assets/img/lodges/masonic_craft.png';

export const copiriFetch = async (url, data = {}, headers = {}, method = 'POST') => {
	return fetch(url, {
		method: 'POST',
		credentials: 'same-origin',
		body: data
	});
};

axios.interceptors.request.use(
	function(config) {
		return config;
	},
	function(error) {
		// Do something with request error
		return Promise.reject(error);
	}
);

axios.interceptors.response.use(
	function(response) {
		let checkData = JSON.parse(localStorage.getItem('idiom'));
		let data = [];
		if (checkData) {
			data = checkData.ERR;
		}
		if (checkSiteUrlAccess() && checkSiteUrlAccess()==='payments') {
			if ((response?.status===401) || (response?.status===440)) {
				sessionLogout()
			}
		} else {
			if (response.data.status === 'LOGOUT' || response.status === 401 || response.status === 440) {
				if (localStorage.getItem('palaver') === null) {
				} else {
					toast.error(data['ERR007'] ? data['ERR007'] : '');
					localStorage.setItem('last_url_location', '/'); // eslint-disable-next-line
				}
				sessionLogout();
			}
		}
		return response;
	},
	function(error) {
		let checkLangData = JSON.parse(localStorage.getItem('idiom'));
		let langData = [];
		if (checkLangData) {
			langData = checkLangData.ERR;
		}
		if (checkSiteUrlAccess() && checkSiteUrlAccess()==='payments') {
			if ((error?.response?.status === 401) || (error?.response?.status === 440)) {
				sessionLogout()
			} else if (error?.response?.data?.result) {
				toast.error(error.response.data.result);				
			} else {
				toast.error(checkLangData?.APY?.WLC?.WLC010);
			}
		} else {
			if (error.response.status === 401 || error.response.status === 440) {
				localStorage.setItem('last_url_location', '/'); // eslint-disable-next-line
				sessionLogout();
				toast.error(langData['ERR007'] ? langData['ERR007'] : '');
			}
		}
		return Promise.reject(error);
	}
);

export const copiriAxiosPost = async (url, data = {}) => {
	return await axios.post(url, data, {
		headers: {
			'Content-Type': 'application/json'
		},
		withCredentials: true
	});
};

export const copiriPortalRequest = async (url, app_version, method, data = '') => {
	let token = localStorage.getItem('palaver');
	if (data !== '') {
		return await axios({
			method: method,
			url: config.api_url + '/v3' + url,
			data: data,
			withCredentials: true,
			headers: {
				Authorization: 'Bearer ' + token,
				'Content-Type': 'multipart/form-data'
				//'Content-Type' : 'application/x-www-form-urlencoded',
			}
		});
	} else {
		return await axios({
			method: method,
			withCredentials: true,
			url: config.api_url + '/v3' + url,
			headers: {
				Authorization: 'Bearer ' + token,
				'Content-Type': 'application/x-www-form-urlencoded'
			}
		});
	}
};

export const copiriBankingPortalRequest = async (url, app_version, method, data = '') => {
	if (data !== '') {
		return await axios({
			method: method,
			url: config.api_url + '/v3' + url,
			data: data,
			withCredentials: true,
			headers: {
				'Content-Type': 'multipart/form-data'
				//'Content-Type' : 'application/x-www-form-urlencoded',
			}
		});
	} else {
		return await axios({
			method: method,
			withCredentials: true,
			url: config.api_url + '/v3' + url,
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded'
			}
		});
	}
};

export const copiriPortalBankingRequest = async (url, app_version, method, data = '',auth=false) => {
	let token = localStorage.getItem('palaver');
	if (auth) {
		return await axios({
			method: method,
			url: config.api_url + url,
			data: data,
			withCredentials: true,
			headers: {
				Authorization: 'Bearer ' + token,
				'Content-Type': 'application/json'
				//'Content-Type' : 'application/x-www-form-urlencoded',
			}
		});
	} else {
		return await axios({
			method: method,
			url: config.api_url + url,
			data: data,
			withCredentials: true,
			headers: {
				'Content-Type': 'application/json'
				//'Content-Type' : 'application/x-www-form-urlencoded',
			}
		});
	}	
};

export const copiriAxiosGet = async url => {
	return await axios.get(url, {
		withCredentials: true,
		exposedHeaders: ['Set-Cookie'],
		headers: {
			'Content-Type': 'application/json'
		}
	});
};

export const sessionLogout = async () => {
	if (checkSiteUrlAccess() && checkSiteUrlAccess()==='payments') {
		let checkData = JSON.parse(localStorage.getItem('idiom'));
		if (checkData?.APY?.WLC) {
			let sessionData = checkData.APY.WLC;
			toast.error(sessionData['WLC011']?sessionData['WLC011']:'');
		}
		setTimeout(() => (window.location.href = '/'), 1000);
	} else {
		localStorage.clear();
		let redirectURl = 'https://' + window.location.host + '/authentication/basic/login';
		setTimeout(() => (window.location.href = redirectURl), 500);
	}
};

export function checkValidation(str, type) {
	// eslint-disable-next-line
	let regexURL = /(http|https):\/\/(\w+:{0,1}\w*)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;
	let regexEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	let regexPhone = /^[0-9+]*$/;
	//if type === url
	if (type === 'url') {
		return regexURL.test(str);
	}

	//if type == email
	if (type === 'email') {
		return regexEmail.test(str);
	}

	//if type == phone
	if (type === 'phone') {
		return regexPhone.test(str);
	}
}

export function getLanguagePackUrl() {
	let url = '';
	let language_id = localStorage.getItem('shibboleth');
	url = '/languages/' + language_id + '/package/PRT';
	return url;
}

export function checkSiteUrlAccess() {
	//let site_url = localStorage.getItem('site_type');		
	let site_url = '';			
	if (/banking.copiri.com$/.test(window.location.hostname)) {
		site_url =  'banking';
	}
	else if (
        /ahepa.copiri.com$/.test(window.location.hostname) || 
        /pay.ahepa.app$/.test(window.location.hostname)    || 
        /pay.ahe.pa$/.test(window.location.hostname)
	) {
		site_url = 'payments';
	}
	else if (
        /portal.copiri.com$/.test(window.location.hostname) 
	) {
		site_url = 'portal';
	}
	else {
		site_url = 'banking'; // this will be for testing puprose.
	}
	
	return site_url;
}

/* function to return custom map icons */
export function getMarkerIcon(id, affiliation) {			// eslint-disable-next-line
	let url = '';							// eslint-disable-next-line
	 if (1        == id) return url = masonic_craft_gldc;          	// eslint-disable-next-line
    else if (3        == id) return url = masonic_craft_glny     	// eslint-disable-next-line
    else if (11       == id) return url = masonic_craft_glnj       	// eslint-disable-next-line
    else if (23       == id) return url = masonic_craft_glks       	// eslint-disable-next-line
    else if (29       == id) return url = masonic_craft_glfl       	// eslint-disable-next-line
    else if (10296    == id) return url = masonic_craft_glspr      	// eslint-disable-next-line
    else if (44837    == id) return url = masonic_sr_srsj           	// eslint-disable-next-line
    else if (50826    == id) return url = masonic_oes_tx_pha       	// eslint-disable-next-line
    else if (50276    == id) return url = ahepa                    	// eslint-disable-next-line
    else if (21041801 == affiliation) return url = masonic_sr      	// eslint-disable-next-line
    else if (21041806 == affiliation) return url = masonic_shrine  	// eslint-disable-next-line
    else if (21041789 == affiliation) return url = masonic_craft   	// eslint-disable-next-line

    return url = default_icon  						// Default
}
