// eslint-disable-next-line
import React, { useState, useEffect } from 'react'; // eslint-disable-next-line
import { copiriPortalRequest, copiriPortalBankingRequest, sessionLogout } from '../api/api'; // eslint-disable-next-line
import config from '../config/config'; // eslint-disable-next-line
import loader_image from '../../assets/img/loader/small_loader.gif'; // eslint-disable-next-line
import Logo from '../navbar/Logo'; // eslint-disable-next-line
import StripeAccountTree from './StripeAccountTree'; // eslint-disable-next-line
import { Link } from 'react-router-dom'; // eslint-disable-next-line
import { Media } from 'reactstrap'; // eslint-disable-next-line
import CopiriLoader from '../common/CopiriLoader';
import default_org_img from '../../assets/img/logos/no_gl_seal_light.png'; // eslint-disable-next-line
import Footer from '../footer/Footer';
import PaymentModal from './PaymentModal';
import { Redirect } from 'react-router-dom';
const BankingManagement = props => {
	// eslint-disable-next-line
	const [sellerId, setSellerId] = useState();
	const [showLoader, setShowLoader] = useState(true); // eslint-disable-next-line
	const [checkAPI, setCheckAPI] = useState(true); // eslint-disable-next-line
	const [paymentData, setPaymentData] = useState([]); // eslint-disable-next-line
	const [checkLoader, setCheckLoader] = useState([]); // eslint-disable-next-line
	const [inValidateLink, setInValidateLink] = useState(false);
	const [pageLanguageContentData, setPageLanguageContentData] = useState([]);
	const [groupDetails, setGroupDetails] = useState({
		id:'first',
		show:true
	});
	const [showHide, setShowHide] = useState(false);
	var checkSession = localStorage.getItem('palaver');
	useEffect(() => {
		getLanguagePack();
		// eslint-disable-next-line
	}, []);

	function getLanguagePack() {
		if (checkSession) {
			setShowLoader(true);
			var checkData = JSON.parse(localStorage.getItem('idiom'));
			var lang_id = 177394503;
			var url = '/languages/' + lang_id + '/package/PRT/PUB';
			copiriPortalRequest(url, config.app_version, 'get')
				.then(response => {
					document.title = response.data?.result?.PUB?.PUB004;
				})
				.catch(error => {});
			if (checkData?.BNK?.HME) {
				setPageLanguageContentData(checkData.BNK.HME);
				getBankingInfoData();
			} else {
				storelanguageTranslatedText(lang_id);
			}
		}
	}

	/* function to get/set the translated text in local storage*/
	function storelanguageTranslatedText(lang_id) {
		copiriPortalRequest('/languages/' + lang_id + '/package/BNK', config.app_version, 'get')
			.then(response => {
				setShowLoader(false);
				if (response?.data?.result?.HME) {
					setPageLanguageContentData(response.data.result.HME);
					let publicTextData = JSON.parse(localStorage.getItem('idiom'));
					let pubArr = publicTextData ? publicTextData : {};
					var storeTranslatedText = {};
					storeTranslatedText['BNK'] = response.data.result;
					let cancatArr = Object.assign({}, storeTranslatedText, pubArr);
					localStorage.setItem('idiom', JSON.stringify(cancatArr));
				}
				getBankingInfoData();
			})
			.catch(error => {
				getBankingInfoData();
			});
	}

	// eslint-disable-next-line
	function getBankingInfoData() {
		setPaymentData([]);
		setShowLoader(true);
		setCheckAPI(true);
		var user_id = localStorage.getItem('user_id');
		if (user_id && user_id !== null && user_id !== undefined) {
			copiriPortalBankingRequest('/web/users/' + user_id + '/banking/accounts', config.app_version, 'get','',true)
				.then(response => {
					setShowLoader(false);
					setCheckAPI(false);
					if (response.data.banking.length > 0) {
						setPaymentData(response.data.banking);
					}
				})
				.catch(error => {
					setShowLoader(false);
					setCheckAPI(false);
				});
		} else {
			sessionLogout();
		}
	}

	function showHideDashboard(id,index) { 
		var arr = {}
		if (groupDetails && groupDetails['id'] ===id && groupDetails['show']==true) {
			//setGroupDetails({id:id,show:false});
			arr['id'] = id;
			arr['show'] = false;
		} else {
			arr['id'] = id;
			arr['show'] = true;
		}
		setGroupDetails(arr)
	}
	const getStripeLink = id => {
		setInValidateLink(true);
		setCheckLoader({
			...checkLoader,
			[id]: true
		});
		copiriPortalRequest('/sellers/' + id + '/onboard/link', config.app_version, 'get')
			.then(response => {
				if (response.data.url) {
					window.open(response.data.url, '_blank');
				} else {
					alert(pageLanguageContentData['HME006']);
				}
				setTimeout(() => {
					setCheckLoader([]);
					setInValidateLink(false);
				}, 5000);
			})
			.catch(error => {
				if (!error.response.status === 401 || !error.response.status === 440) {
					alert(pageLanguageContentData['HME006']);
				}
				setTimeout(() => {
					setCheckLoader([]);
					setInValidateLink(false);
				}, 5000);
			});
	};

	/* function to show modal */
	const showModal = () => {
		setShowHide(!showHide);
	}
	/* function to close modal */
	const closeModal = () => {
		setShowHide(!showHide);
	};
	/* function to update functionality on modal */
	const updateModal = index => {
		setShowHide(!showHide);
	};

	if (!checkSession) {
		return <Redirect to='/authentication/basic/login' />;
	}
	
	return (
		<div className='banking_site_layout'>
			<div className='row'>
				<div style={{ textAlign: 'center', marginTop: '10px' }} className='col-12 loginLogo col'>
					<Logo at='navbar-top' width={40} id='topLogo' style={{ marginLeft: '15px' }} />
				</div>
			</div>
			{showLoader ? (
				<CopiriLoader />
			) : (
				<div className='flex-center pt-2 mb-pading '>
					<div className='payment-baniking-dev'>
						<div className='p-0 shadownone card'>
							<div className='fs--1 font-weight-normal p-0 card-body'>
								<h5 className='mb-0' style={{ backgroundColor: '#fff', padding: '10px 15px' }}>
									<i className='fas fa-landmark'></i> <span style={{ marginLeft: '5px' }}>Banking Management</span>
								</h5>
							</div>
							<div className='accountCOntent' style={{ padding: '0px' }}>
								<div className='accordion-main bg_color_1'>
									<div className='accordion-item'>
										<div className='accordion-header d-flex align-items-center' onClick={() => showHideDashboard('first')}>
											<span className='accordion-icon'>
												{' '}
												<i className={(groupDetails && groupDetails['id']==='first'  && groupDetails['show']) ? 'fas fa-angle-down' : 'fas fa-angle-right'}></i>{' '}
											</span>
											<span className='org_img'>
												<img
													className='rounded-circle avatar-xl'
													src={default_org_img}
													alt='img'
													width='100px'
													id={''}
												/>
											</span>
											<p className='org_name mb-0'>The Grand Lodge F.A.A.M., of District of Columbia</p>
										</div>

										<div className={(groupDetails && groupDetails['id']==='first'  && groupDetails['show']) ? 'accordion-bodyaccordion-body' : 'accordion-body d-none'} >
											<div className='card-list'>
												<div className='row'>
													<div className='col-lg-3 col-md-6 col-12 px-1'>
														<div className='card' style={{cursor:'default'}}>
															<span className='dashboard_tile'>Balance On Hand</span>
															<p className='amount-number m-0'>$21,000</p>
														</div>
													</div>
													<div className='col-lg-3 col-md-6 col-12 px-1'>
														<div className='card' onClick={() => showModal()}>
															<span className='dashboard_tile'>Total Paid</span>
															<p className='amount-number m-0'>$21,000</p>
														</div>
													</div>
													<div className='col-lg-3 col-md-6 col-12 px-1'>
														<div className='card' onClick={() => showModal()}>
															<span className='dashboard_tile'>Total Payments</span>
															<p className='amount-number m-0'>23</p>
															<div className='progress'>
																<div
																	className='progress-bar'
																	role='progressbar'
																	aria-valuenow='20'
																	aria-valuemin='20'
																	aria-valuemax='100'
																></div>
															</div>
														</div>
													</div>
													<div className='col-lg-3 col-md-6 col-12 px-1'>
														<div className='card bank_card' onClick={() => getStripeLink('d753c687-bcbb-4abc-8e3c-71fd8f3c40ac')}>
															<div className='bank-details'>
																<p className='amount-number'>Active</p>
																<p className='bankname'>Bank of America</p>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>

								<div className='accordion-main bg_color_2'>
									<div className='accordion-item'>
										<div className='accordion-header d-flex align-items-center' onClick={() => showHideDashboard('second')}>
											<span className='accordion-icon'>
												{' '}
												<i className={(groupDetails && groupDetails['id']==='second'  && groupDetails['show']) ? 'fas fa-angle-down' : 'fas fa-angle-right'}></i>{' '}
											</span>
											<span className='org_img'>
												<img
													className='rounded-circle avatar-xl'
													src={default_org_img}
													alt='img'
													width='100px'
													id={''}
												/>
											</span>
											<p className='org_name mb-0'>Northeast</p>
										</div>

										<div className={(groupDetails && groupDetails['id']==='second'  && groupDetails['show']) ? 'accordion-bodyaccordion-body' : 'accordion-body d-none'}>
											<div className='card-list'>
												<div className='row'>
													<div className='col-lg-3 col-md-6 col-12 px-1'>
														<div className='card' style={{cursor:'default'}}>
															<span className='dashboard_tile'>Balance On Hand</span>
															<p className='amount-number m-0'>$21,000</p>
														</div>
													</div>
													<div className='col-lg-3 col-md-6 col-12 px-1'>
														<div className='card' onClick={() => showModal()}>
															<span className='dashboard_tile'>Total Paid</span>
															<p className='amount-number m-0'>$21,000</p>
														</div>
													</div>
													<div className='col-lg-3 col-md-6 col-12 px-1'>
														<div className='card' onClick={() => showModal()}>
															<span className='dashboard_tile'>Total Payments</span>
															<p className='amount-number m-0'>23</p>
															<div className='progress'>
																<div
																	className='progress-bar'
																	role='progressbar'
																	aria-valuenow='20'
																	aria-valuemin='20'
																	aria-valuemax='100'
																></div>
															</div>
														</div>
													</div>
													<div className='col-lg-3 col-md-6 col-12 px-1'>
														<div className='card bank_card' onClick={() => getStripeLink('d753c687-bcbb-4abc-8e3c-71fd8f3c40ac')}>
															<div className='bank-details'>
																<p className='amount-number'>Active</p>
																<p className='bankname'>Bank of America</p>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>

								<div className='accordion-main '>
									<div className='accordion-item'>
										<div className='accordion-header d-flex align-items-center' onClick={() => showHideDashboard('third')}>
											<span className='accordion-icon'>
												{' '}
												<i className={(groupDetails && groupDetails['id']==='third'  && groupDetails['show']) ? 'fas fa-angle-down' : 'fas fa-angle-right'}></i>{' '}
											</span>
											<span className='org_img'>
												<img
													className='rounded-circle avatar-xl'
													src={default_org_img}
													alt='img'
													width='100px'
													id={''}
												/>
											</span>
											<p className='org_name mb-0'>Potomac Lodge No. 5</p>
										</div>

										<div className={(groupDetails && groupDetails['id']==='third'  && groupDetails['show']) ? 'accordion-bodyaccordion-body' : 'accordion-body d-none'}>
											<div className='card-list'>
												<div className='row'>
													<div className='col-lg-3 col-md-6 col-12 px-1'>
														<div className='card' style={{cursor:'default'}}>
															<span className='dashboard_tile'>Balance On Hand</span>
															<p className='amount-number m-0'>$21,000</p>
														</div>
													</div>
													<div className='col-lg-3 col-md-6 col-12 px-1'>
														<div className='card' onClick={() => showModal()}>
															<span className='dashboard_tile'>Total Paid</span>
															<p className='amount-number m-0'>$21,000</p>
														</div>
													</div>
													<div className='col-lg-3 col-md-6 col-12 px-1'>
														<div className='card' onClick={() => showModal()}>
															<span className='dashboard_tile'>Total Payments</span>
															<p className='amount-number m-0'>23</p>
															<div className='progress'>
																<div
																	className='progress-bar'
																	role='progressbar'
																	aria-valuenow='20'
																	aria-valuemin='20'
																	aria-valuemax='100'
																></div>
															</div>
														</div>
													</div>
													<div className='col-lg-3 col-md-6 col-12 px-1'>
														<div className='card bank_card' onClick={() => getStripeLink('d753c687-bcbb-4abc-8e3c-71fd8f3c40ac')}>
															<div className='bank-details'>
																<p className='amount-number'>Active</p>
																<p className='bankname'>Bank of America</p>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>

								<div className='accordion-main '>
									<div className='accordion-item'>
										<div className='accordion-header d-flex align-items-center' onClick={() => showHideDashboard('fourth')}>
											<span className='accordion-icon'>
												{' '}
												<i className={(groupDetails && groupDetails['id']==='fourth'  && groupDetails['show']) ? 'fas fa-angle-down' : 'fas fa-angle-right'}></i>{' '}
											</span>
											<span className='org_img'>
												<img
													className='rounded-circle avatar-xl'
													src={default_org_img}
													alt='img'
													width='100px'
													id={''}
												/>
											</span>
											<p className='org_name mb-0'>Lodge Adhuc Stat No. 1782</p>
										</div>

										<div className={(groupDetails && groupDetails['id']==='fourth'  && groupDetails['show']) ? 'accordion-bodyaccordion-body' : 'accordion-body d-none'}>
											<div className='card-list'>
												<div className='row'>
													<div className='col-lg-3 col-md-6 col-12 px-1'>
														<div className='card' style={{cursor:'default'}}>
															<span className='dashboard_tile'>Balance On Hand</span>
															<p className='amount-number m-0'>$21,000</p>
														</div>
													</div>
													<div className='col-lg-3 col-md-6 col-12 px-1'>
														<div className='card' onClick={() => showModal()}>
															<span className='dashboard_tile'>Total Paid</span>
															<p className='amount-number m-0'>$21,000</p>
														</div>
													</div>
													<div className='col-lg-3 col-md-6 col-12 px-1'>
														<div className='card' onClick={() => showModal()}>
															<span className='dashboard_tile'>Total Payments</span>
															<p className='amount-number m-0'>23</p>
															<div className='progress'>
																<div
																	className='progress-bar'
																	role='progressbar'
																	aria-valuenow='20'
																	aria-valuemin='20'
																	aria-valuemax='100'
																></div>
															</div>
														</div>
													</div>
													<div className='col-lg-3 col-md-6 col-12 px-1'>
														<div className='card bank_card' onClick={() => getStripeLink('d753c687-bcbb-4abc-8e3c-71fd8f3c40ac')}>
															<div className='bank-details'>
																<p className='amount-number'>Active</p>
																<p className='bankname'>Bank of America</p>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className='flex-center row'>
								<div style={{ textAlign: 'center', marginTop: '20px' }} className='col-12  col'>
									<Link to='/authentication/basic/logout'>
										<span
											className='nav-link-text'
											style={{ fontSize: '14px', fontWeight: '600', cursor: 'pointer', color: '' }}
										>
											{pageLanguageContentData['HME004'] ? pageLanguageContentData['HME004'] : ''}
										</span>
									</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
			<Footer />
			<PaymentModal
				showHide={showHide}
				onClick={closeModal}
				updateModal={updateModal}
			/>
		</div>
	);
};
export default BankingManagement;
