/* eslint react/no-multi-comp: 0, react/prop-types: 0 */
import React from 'react';

// eslint-disable-next-line
import { Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter, Form } from 'reactstrap';
const PaymentModal = ({ showHide, className, onClick, count, path, updateModal, pageLanguageContent }) => {
	return (
		<div>
			<Modal isOpen={showHide} toggle={onClick} size='lg' id="payment_modal">
				<ModalHeader toggle={onClick}>Payment Detail</ModalHeader>
				<ModalBody>
					<Form>
						<Row>
							<Col md={12}>
								<h5 className='mb-2 p-3' style={{textAlign:'center',borderBottom:'1px dashed #ccc'}}>
									Recent Transactions
								</h5>
							</Col>
						</Row>
					</Form>					
					<Row>
						<Col xs={12}>
							<h6 className='mt-3' style={{textAlign:'center'}}><strong>No reocrd found</strong></h6>
						</Col>										
					</Row>
				</ModalBody>
				<ModalFooter>
					<Button color='secondary' onClick={onClick}>
						Close
					</Button>
				</ModalFooter>
			</Modal>
		</div>
	);
};

export default PaymentModal;
